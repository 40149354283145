// Colors
$color1: #6b4444;
$color2: rgba(255, 255, 255, 1);
$color3: #d3c9bc;
$color4: rgba(2, 17, 27, 1);
$color5: rgba(48, 41, 47, 1);


html, body {
  height: 100%;
}

html {
  background-color: $color3;
  font-size: 62.5%;
  overflow-x: hidden;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  color: $font-color;
  font-family: $font-body;
  margin: 0;
  padding: 0;

  @include breakpoint(small) {
    display: grid;
    font-size: 1.2rem;
    grid-template-areas: 'header' 'nav-bar' 'main' 'footer';
    grid-template-columns: 1fr;
    grid-template-rows: 8rem 6rem auto auto;
  }

  @include breakpoint (med) {
    font-size: 1.6rem;
    grid-template-rows: 12rem 4rem auto auto;
  }

  @include breakpoint (large) {
    grid-template-rows: 8rem 4rem auto auto;
  }

}

main {
  background-color: $color1;
  border-bottom: 2px solid $color3;
  color: $color3;
  display: flex;
  grid-area: main;
  justify-content: center;

  @include breakpoint (small) {
    padding: 2rem 1rem;


  }
}

header {
  font-family: $font-header;
  grid-area: header;
  text-align: center;
}

nav {
  border-bottom: 2px solid $color5;
  grid-area: nav-bar;
  text-transform: uppercase;

  ul {
    display: flex;
    flex-direction: row;
  }

  a {
    box-shadow: 0 -1px 0 0 $color3 inset, 0 -2px 0 0 transparent inset;
    color: $color5;
    margin-top: 2rem;
    text-decoration: none;
    text-shadow: 0 -2px 0 $color3, 0 -1px 0 $color3, 0 0 0 $color3, 2px -2px 0 $color3, 2px -1px 0 $color3, 2px 0 0 $color3, -2px -2px 0 $color3, -2px -1px 0 $color3, -2px 0 0 $color3, 1px -2px 0 $color3, 1px -1px 0 $color3, 1px 0 0 $color3, -1px -2px 0 $color3, -1px -1px 0 $color3, -1px 0 0 $color3, 0 -2px 0 $color3, 0 -1px 0 $color3, 0 0 0 $color3;
    transition: .3s ease-in;

    &:hover {
      box-shadow: 0 -1px 0 0 $color3 inset, 0 -2px 0 0 $color5 inset;
      transition: .3s ease-out;
    }

    &::selection {
      background: $color5;
      color: $color3;
      text-shadow: none;
    }
  }

  @include breakpoint (small) {
    padding: 2rem .5rem;

    ul {
      justify-content: space-around;
    }

  }
}

footer {
  background-color: $color5;
  color: $color3;
  grid-area: footer;
  margin: 0;
  text-align: center;

  .second-line {
    display: block;
  }

  .footer-links {
    display: flex;
    justify-content: space-around;
    padding: 1rem;

    .contact-details {
      ul {
        align-self: center;
      }
    }
  }

  .footer-nav {
    margin: 1rem 0;

    ul {
      li {
        margin: 2rem 0;
      }
    }
  }

}

ul {
  list-style: none;
}

header,
nav {
  background-color: $color3;
  color: $color5;
}

input, textarea, keygen, select, button {
  font-size: inherit;
}

button, select {

  &:hover {
    cursor: pointer;
  }

}

svg {
  align-self: center;
  display: inline-flex;
}

h1, h2, h3, h4 {
  font-family: $font-header;
  margin: 1rem 0;
  text-transform: uppercase;
}

#spouse-info,
#spouse-employment,
#other-info,
#pet-info {
  display: none;
}

.rental-application, .message-us {

  margin: 2rem 1rem;

  textarea {
    height: 12rem;
    resize: none;
  }

  @include breakpoint(small) {
    font-size: 2rem;

    ul {
      margin: .5rem;

      li {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: .5rem;
        max-width: 275px;
      }

      li > label,
      li > fieldset {
        flex: 1 0 275px;
      }

      li > label + * {
        flex: 1 0 275px;
      }
    }
  }

  @include breakpoint(med) {
    align-self: center;
    display: flex;
    flex-direction: column;

    ul {

      li {
        flex-wrap: nowrap;
        max-width: none;
      }

      li > label,
      li > fieldset {
        flex: 1 0 225px;
        max-width: 225px;
      }

      li > label + * {
        flex: 1 0 200px;
        max-width: 350px;
      }
    }
  }
}


.picture {
  border: 2px solid $color3;
  line-height: 0;
}

.button {
  align-self: center;
  border: 2px solid $color3;
  display: block;
  font-size: 1.6rem;
  height: 4rem;
  margin: 1rem;
  padding: .5rem 2rem;
  text-align: center;
  width: 14rem;

  &:hover {
    background-color: $color3;
    color: $color1;
  }
}

.error-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 300px;
  margin: 10px auto;
  max-width: 600px;
  padding: 1rem;
  text-align: center;

  p {
    padding: 2rem;
  }
}


// Media Queries
@media only screen and (min-width: 320px) {


  header,
  footer {
    padding: 1rem .5rem;
  }

  .footer-links {
    flex-direction: column;
  }


}

// Tablet
@media only screen and (min-width: 640px) {


  header,
  nav,
  footer {
    padding: 1rem;
  }

  header {
    font-size: 2rem;
  }

  nav {
    font-size: 1.5rem;

    ul {
      justify-content: center;
    }

    li {
      margin: 0 3rem;
    }
  }

  .footer-links {
    flex-direction: row;
  }

}
// Desktop
@media only screen and (min-width: 920px) {

  header {
    font-size: 2.5rem;
  }

  nav {
    li {
      margin: 0 6rem;
    }
  }


}

.map-container {
  height: 100%;
}

.map-window {
  height: 100%;
  width: 100%;
}

.page-container, .home-content {

  @include breakpoint(small) {
    background-color: $color5;
    border: 2px solid $color3;
    min-height: 80vh;
    padding: 1rem;
  }

  @include breakpoint(large) {
    width: 880px;
  }

  @include breakpoint(wide) {
    width: 1000px;
  }

}

.home-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    margin: 1.5rem 0;
  }
}

.page-container {

  @include breakpoint(small) {
    display: flex;
    flex-direction: column;
    margin: .5rem;
  }

  @include breakpoint(med) {
    margin: 1rem;
  }
}

.contact-container {
  display: flex;

  @include breakpoint(small) {
    align-items: center;
    flex-direction: column;
    padding: .25rem;
  }

  @include breakpoint (large) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;

    div {
      width: 50%;
    }
  }

}

.contact-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  margin: 1rem;


  hr {
    align-self: center;
    background-color: $color3;
    height: .3rem;
    width: 80%;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  li {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin: 1.5rem 0;

  }

  svg {
    margin-right: 1rem;
  }

}
