
%clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

.gallery {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.gallery-image {
  border: 2px solid $color3;
  line-height: 0;

  @include breakpoint (small) {
    margin: 1rem 0;

  }

  @include breakpoint (med) {
    margin: 1rem;
  }
  @include breakpoint (large) {
    margin: 1.5rem;
  }

  picture {
    height: 300px;
    vertical-align: middle;
  }
}

.image-desc {
  padding: 1.5rem;
  text-align: center;
}
