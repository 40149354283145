// sorts-mill-goudy-regular - latin
@font-face {
  font-family: 'Sorts Mill Goudy';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/sorts-mill-goudy-v7-latin-regular.eot'); // IE9 Compat Modes */
  src: local('Sorts Mill Goudy Regular'), local('SortsMillGoudy-Regular'),
       url('../assets/fonts/sorts-mill-goudy-v7-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../assets/fonts/sorts-mill-goudy-v7-latin-regular.woff2') format('woff2'), // Super Modern Browsers
       url('../assets/fonts/sorts-mill-goudy-v7-latin-regular.woff') format('woff'), // Modern Browsers
       url('../assets/fonts/sorts-mill-goudy-v7-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
       url('../assets/fonts/sorts-mill-goudy-v7-latin-regular.svg#SortsMillGoudy') format('svg'); // Legacy iOS
}

$font-header: 'Sorts Mill Goudy', serif;
$font-body: 'Montserrat', sans-serif;
$font-color: #262626;


$breakpoints: (
  'small': 320px,
  'mobile': 375px,
  'med':   640px,
  'large': 920px,
  'wide':  1200px,
  'mega':  1400px,
  );

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else
  if type_of($breakpoint) == number and unit($breakpoint) == px or unit($breakpoint) == em or unit($breakpoint) == rem {
    @media (min-width: $breakpoint) {
      @content;
    }
  } @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. " + "It is either not defined in the `$breakpoints` map, or it is an invalid number of px, em, or rem.";
  }
}

*,
*::after,
*::before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  &,
  :active,
  :hover,
  :visited {
    color: inherit;
    text-decoration: none;
  }
}
