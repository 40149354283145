
// Montserrat Thin
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-Thin.eot");
    src: url("../assets/fonts/Montserrat-Thin.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Thin.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Thin.woff") format("woff");
}

// Montserrat Thin-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-ThinItalic.eot");
    src: url("../assets/fonts/./assets/fonts/Montserrat-ThinItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/./assets/fonts/Montserrat-ThinItalic.woff2") format("woff2"),
         url("../assets/fonts/./assets/fonts/Montserrat-ThinItalic.woff") format("woff");
}

// Montserrat ExtraLight
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-ExtraLight.eot");
    src: url("../assets/fonts/Montserrat-ExtraLight.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/./assets/fonts/Montserrat-ExtraLight.woff2") format("woff2"),
         url("../assets/fonts/./assets/fonts/Montserrat-ExtraLight.woff") format("woff");
}

// Montserrat ExtraLight-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-ExtraLightItalic.eot");
    src: url("../assets/fonts/Montserrat-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-ExtraLightItalic.woff") format("woff");
}

// Montserrat Light
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("../assets/fonts/./assets/fonts/Montserrat-Light.eot");
    src: url("../assets/fonts/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Light.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Light.woff") format("woff");
}

// Montserrat Light-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-LightItalic.eot");
    src: url("../assets/fonts/Montserrat-LightItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-LightItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-LightItalic.woff") format("woff");
}

// Montserrat Regular
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-Regular.eot");
    src: url("../assets/fonts/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Regular.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Regular.woff") format("woff");
}

// Montserrat Regular-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-Italic.eot");
    src: url("../assets/fonts/Montserrat-Italic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Italic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Italic.woff") format("woff");
}

// Montserrat Medium
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-Medium.eot");
    src: url("../assets/fonts/Montserrat-Medium.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Medium.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Medium.woff") format("woff");
}

// Montserrat Medium-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-MediumItalic.eot");
    src: url("../assets/fonts/Montserrat-MediumItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-MediumItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-MediumItalic.woff") format("woff");
}

// Montserrat SemiBold
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-SemiBold.eot");
    src: url("../assets/fonts/Montserrat-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-SemiBold.woff") format("woff");
}

// Montserrat SemiBold-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-SemiBoldItalic.eot");
    src: url("../assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-SemiBoldItalic.woff") format("woff");
}

// Montserrat Bold
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-Bold.eot");
    src: url("../assets/fonts/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Bold.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Bold.woff") format("woff");
}

// Montserrat Bold-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-BoldItalic.eot");
    src: url("../assets/fonts/Montserrat-BoldItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-BoldItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-BoldItalic.woff") format("woff");
}

// Montserrat ExtraBold
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-ExtraBold.eot");
    src: url("../assets/fonts/Montserrat-ExtraBold.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-ExtraBold.woff") format("woff");
}

// Montserrat ExtraBold-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-ExtraBoldItalic.eot");
    src: url("../assets/fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-ExtraBoldItalic.woff") format("woff");
}

// Montserrat Black
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("../assets/fonts/Montserrat-Black.eot");
    src: url("../assets/fonts/Montserrat-Black.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-Black.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-Black.woff") format("woff");
}

// Montserrat Black-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("../assets/fonts/Montserrat-BlackItalic.eot");
    src: url("../assets/fonts/Montserrat-BlackItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/Montserrat-BlackItalic.woff2") format("woff2"),
         url("../assets/fonts/Montserrat-BlackItalic.woff") format("woff");
}

// =================== MONTSERRAT ALTERNATES ===================

// Montserrat Alternates Thin
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 100;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-Thin.eot");
    src: url("../assets/fonts/MontserratAlternates-Thin.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Thin.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Thin.woff") format("woff");
}

// Montserrat Alternates Thin-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 100;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-ThinItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-ThinItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-ThinItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-ThinItalic.woff") format("woff");
}

// Montserrat Alternates ExtraLight
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 200;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-ExtraLight.eot");
    src: url("../assets/fonts/MontserratAlternates-ExtraLight.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-ExtraLight.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-ExtraLight.woff") format("woff");
}

// Montserrat Alternates ExtraLight-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 200;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-ExtraLightItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-ExtraLightItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-ExtraLightItalic.woff") format("woff");
}

// Montserrat Alternates Light
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 300;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-Light.eot");
    src: url("../assets/fonts/MontserratAlternates-Light.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Light.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Light.woff") format("woff");
}

// Montserrat Alternates Light-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 300;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-LightItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-LightItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-LightItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-LightItalic.woff") format("woff");
}

// Montserrat Alternates Regular
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 400;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-Regular.eot");
    src: url("../assets/fonts/MontserratAlternates-Regular.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Regular.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Regular.woff") format("woff");
}

// Montserrat Alternates Regular-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 400;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-Italic.eot");
    src: url("../assets/fonts/MontserratAlternates-Italic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Italic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Italic.woff") format("woff");
}

// Montserrat Alternates Medium
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-Medium.eot");
    src: url("../assets/fonts/MontserratAlternates-Medium.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Medium.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Medium.woff") format("woff");
}

// Montserrat Alternates Medium-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-MediumItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-MediumItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-MediumItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-MediumItalic.woff") format("woff");
}

// Montserrat Alternates SemiBold
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-SemiBold.eot");
    src: url("../assets/fonts/MontserratAlternates-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-SemiBold.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-SemiBold.woff") format("woff");
}

// Montserrat Alternates SemiBold-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-SemiBoldItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-SemiBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-SemiBoldItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-SemiBoldItalic.woff") format("woff");
}

// Montserrat Alternates Bold
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-Bold.eot");
    src: url("../assets/fonts/MontserratAlternates-Bold.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Bold.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Bold.woff") format("woff");
}

// Montserrat Alternates Bold-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-BoldItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-BoldItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-BoldItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-BoldItalic.woff") format("woff");
}

// Montserrat Alternates ExtraBold
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 800;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-ExtraBold.eot");
    src: url("../assets/fonts/MontserratAlternates-ExtraBold.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-ExtraBold.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-ExtraBold.woff") format("woff");
}

// Montserrat Alternates ExtraBold-Italic
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 800;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-ExtraBoldItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-ExtraBoldItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-ExtraBoldItalic.woff") format("woff");
}

// Montserrat Alternates Black
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 900;
    font-style: normal;
    src: url("../assets/fonts/MontserratAlternates-Black.eot");
    src: url("../assets/fonts/MontserratAlternates-Black.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-Black.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-Black.woff") format("woff");
}

// Montserrat Alternates Black-Italic
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("../assets/fonts/MontserratAlternates-BlackItalic.eot");
    src: url("../assets/fonts/MontserratAlternates-BlackItalic.eot?#iefix") format('embedded-opentype'),
         url("../assets/fonts/MontserratAlternates-BlackItalic.woff2") format("woff2"),
         url("../assets/fonts/MontserratAlternates-BlackItalic.woff") format("woff");
}
